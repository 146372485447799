import moment from "moment";
import { Store } from "redux/store";
// import { useSelector } from "react-redux";
import MultiFloatFilter from "./MultiFloatFilter";

export const headers = [
  {
    headerName: "Project Name",
    field: "projectName",
    filter: MultiFloatFilter,
    filterParams: "projectName",
    lockPosition: "left",
    sortable: true,
    width: 205,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    minWidth: 205,
  },
  {
    headerName: "Country",
    field: "country",

    filter: MultiFloatFilter,
    filterParams: "country",
    lockPosition: "left",

    
    sortable: true,
    width: 170,
    minWidth: 170,
  },

  {
    headerName: "Make",
    lockPosition: "left",
    field: "make",
    filter: MultiFloatFilter,
    filterParams: "make",

    width: 180,
    minWidth: 180,
    sortable: true,
  },
  {
    headerName: "Business Priority",
    field: "issuePriority",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "priorityscope",
    },

    lockPosition: "left",
    filter: MultiFloatFilter,
    filterParams: "issuePriority",
    minWidth: 180,
    //maxWidth: 150,
    sortable: true,
  },
  {
    headerName: "Model",
    lockPosition: "left",
    field: "model",
    filter: MultiFloatFilter,
    filterParams: "model",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "scopemodel",
    },
    width: 180,
    minWidth: 180,
    sortable: true,

    wrapText: true,
    autoHeight: true,
  },
 
  {
    headerName: "Month",
    field: "month",
    filter: MultiFloatFilter,
    filterParams: "month",

    width: 150,
    minWidth: 150,
    sortable: true,
    lockPosition: "left",
  },

  {
    headerName: "Source URL",
    field: "sourceURL",
    filter: MultiFloatFilter,
    filterParams: "sourceURL",
    cellClass: "trimText",
    wrapText: true,
    autoHeight: true,
    //tooltipField: "oemUrl",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "scopesourceurl",
    },

    width: 300,
    minWidth: 300,
    lockPosition: "left",
    sortable: true,
  },
  {
    headerName: "Data Availability",
    field: "dataAvailability",
  cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "data",
    },

    lockPosition: "left",
    filter: MultiFloatFilter,
    filterParams: "dataAvailability",

    width: 180,
    minWidth: 180,
    sortable: true,
  },
  {
    headerName: "Reason for Failure",
    field: "reasonforFailure",
    filter: MultiFloatFilter,
    filterParams: "reasonforFailure",
    cellClass: "trimText",
    wrapText: true,
    autoHeight: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "scopereasonforfailure",
    },
    lockPosition: "left",

    width: 200,
    minWidth: 200,
    sortable: true,
  },
  {
    headerName: "Action",
    field: "",
    cellRenderer: "iconCellRenderer",
    cellRendererParams: {
      actionType: "adminaction1",
    },
    width: 140,
    minWidth: 140,
    maxWidth: 140,
    cellClass: ["ag-cell-align-center", "locked-col"],
    // cellStyle: { color: 'red', 'background-color': 'green' }
    lockPosition: "left",
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        "  </div>" +
        "</div>",
    },
  },
];
export const data_availability = [
  {
    id: true,
    value: "YES",
  },
  {
    id: false,
    value: "NO",
  },
];
